<template>
  <div>
    <b-modal
      ref="cancel-store-po-modal"
      hide-footer
      :title="cancelAlert.title"
      border-0
      centered
      id="cancel-store-po-modal"
    >
      <div class="d-block">
        <div>
          <b-row>
            <b-form-group class="col-12">
              <template>
                <span>Lý do huỷ phiếu:</span>
              </template>
              <b-form-textarea
                size="sm"
                v-model="cancelReason"
                placeholder="Nhập lý do huỷ phiếu"
                :rows="3"
                :max-rows="3"
                class="mt-2"
              ></b-form-textarea>
            </b-form-group>
          </b-row>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-end">
        <b-button
          class="mr-2 btn-width-25"
          variant="secondary"
          @click="hideModal"
        >Trở về</b-button>
        <b-button
          variant="danger"
          class="btn-width-25"
          :disabled="!cancelReason"
          @click="confirmCancelStorePO(checkedItem)"
          v-if="!isBusy"
        >Xác nhận</b-button>
        <b-button
          v-else
          variant="danger"
          class="btn-width-25"
          disabled
        >
          <b-spinner
            small
            type="grow"
          ></b-spinner>
          Cập nhật...
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { STATUS_CODE } from '@/utils/enum';
import { makeToastFaile } from '../../../utils/common';
export default {
  props: {
    cancelAlert: {
      type: Object,
      required: true,
    },
    checkedItem: {
      type: Object,
    },
  },
  data() {
    return {
      isBusy: false,
      cancelReason: '',
    };
  },
  methods: {
    showModal() {
      this.$refs['cancel-store-po-modal'].show();
    },
    hideModal() {
      this.cancelReason = null;
      this.$refs['cancel-store-po-modal'].hide();
    },
    async confirmCancelStorePO(checkedItem) {
      try {
        this.isBusy = true;
        const body = {
          cancelReason: this.cancelReason,
        };
        const {
          data: { status },
        } = await ApiService.put(
          `store-purchase-order/${checkedItem.id}/cancel`,
          body,
        );
        if (status === STATUS_CODE.SUCCESS) {
          this.$emit('refreshPage', true);
        }
      } catch ({ response }) {
        const message = response.data?.message || 'Network error';
        return makeToastFaile(message);
      } finally {
        this.isBusy = false;
        this.cancelReason = null;
        this.$refs['cancel-store-po-modal'].hide();
      }
    },
  },
};
</script>

<style scoped>
.btn-width-25 {
  width: 25%;
}
</style>