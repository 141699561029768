<template>
  <div>
    <b-modal
      scrollable
      size="xl"
      :title="title"
      ref="multiple-approve-store-po-modal"
    >
      <table class="table col-md-12 table-bordered border-top-color">
        <thead>
          <tr>
            <th
              class="text-center header-table-color"
              style="width: 7%"
            >Mã</th>
            <th
              class="text-center header-table-color"
              style="width: 10%"
            >
              Kho
            </th>
            <th class="text-center header-table-color">
              Sản phẩm
            </th>
            <th
              class="text-center header-table-color"
              style="width: 8%"
            >Sức bán</th>
            <th
              class="text-center header-table-color"
              style="width: 7%"
            >SL</th>
            <th
              v-if="checkShowApproveQuantityColumn()"
              class="text-center header-table-color"
              style="width: 7%"
            >SL duyệt</th>
            <th
              v-if="checkShowConfirmQuantityColumn()"
              class="text-center header-table-color"
              style="width: 7%"
            >SL xác nhận</th>
            <th
              v-if="checkShowDeliveryDateColumn()"
              class="text-center header-table-color"
              style="width: 13%"
            >Ngày dự kiến</th>
            <th
              class="text-center header-table-color"
              style="width: 15%"
            >
              Nội dung
            </th>
          </tr>
        </thead>
        <tbody
          v-if="!isBusy"
          class="table-body"
        >
          <template v-for="(storePO, i) in storePOComputed">
            <tr
              v-for="(storePODetail, index) in storePO.storePurchaseOrderDetails"
              :key="storePO.code + storePODetail.id + i"
              :class="'tr-'+storePO.id"
              @mouseover="highlightRow(storePO.id)"
              @mouseleave="removeHighlightRow(storePO.id)"
            >
              <td
                class="font-weight-bold"
                v-if="index===0"
                :rowspan="storePO.storePurchaseOrderDetails.length"
              >{{ storePO.code }}</td>
              <td
                v-if="index===0"
                :rowspan="storePO.storePurchaseOrderDetails.length"
              >{{ storePO.storeName }}</td>
              <td>
                <span
                  v-b-tooltip.hover.right="'Product name'"
                  class="pr-1"
                >{{
                    storePODetail.productName
                  }}</span>
                <span class="font-weight-bold">
                  <span :class="storePODetail.inStockQuantity ? 'text-success': 'text-danger'">Tồn: </span>
                  <span
                    v-b-tooltip.hover
                    title="Tồn thực tế"
                    class="text-primary pr-1"
                  >{{ storePODetail.inStockQuantity }}</span> + <span
                    v-b-tooltip.hover
                    title="Tồn đang chuyển kho"
                    class="text-warning font-weight-bold pl-1"
                  >{{ storePODetail.transferQuantity }}</span>
                </span>
              </td>
              <td class="text-left">
                <span
                  class="font-weight-bold"
                  v-b-tooltip.hover.right="'Sức bán tuần'"
                >Tuần:</span> {{ storePODetail.totalSalePowerWeek }}
                <span
                  class="font-weight-bold"
                  v-b-tooltip.hover.right="'Sức bán tháng'"
                >Tháng:</span> {{ storePODetail.totalSalePowerMonth }}
              </td>
              <td class="text-right">
                <b-input
                  type="text"
                  size="sm"
                  class="input-style text-right"
                  v-model="storePODetail.quantity"
                  v-mask="mask"
                  :disabled="storePO.status !== STORE_PO_STATUS.NEW"
                >
                </b-input>
              </td>
              <td
                class="text-right"
                v-if="checkShowApproveQuantityColumn()"
              >
                <b-input
                  type="text"
                  size="sm"
                  class="input-style text-right "
                  v-model="storePODetail.quantityApproved"
                  v-mask="mask"
                  @focus="startEditingRow(storePODetail)"
                  :class="getBorderInput(storePODetail)"
                  :disabled="storePO.status === STORE_PO_STATUS.CONFIRMED"
                >
                </b-input>
              </td>
              <td
                class="text-right"
                v-if="checkShowConfirmQuantityColumn()"
              >
                <b-input
                  type="text"
                  size="sm"
                  class="input-style text-right "
                  v-model="storePODetail.quantityApproved"
                  v-mask="mask"
                  disabled
                >
                </b-input>
              </td>
              <td
                v-if="index===0 && checkShowDeliveryDateColumn()"
                :rowspan="storePO.storePurchaseOrderDetails.length"
              >
                <div class="d-flex justify-content-between align-items-center date-picker-custom">
                  <b-input-group>
                    <date-picker
                      placeholder="Chọn ngày"
                      class="mb-2 mt-2 mr-2 form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="storePO.deliveryDate"
                    ></date-picker>
                  </b-input-group>
                  <span v-b-tooltip.hover.right="'Áp dụng tất cả'">
                    <i
                      role="button"
                      class="fa-solid fa-angles-down text-success"
                      v-if="storePO.deliveryDate && !storePO.isAutoFillDeliveryDate"
                      @click="applyAllDeliveryDate(storePO.id, storePO.deliveryDate)"
                    ></i>
                  </span>
                </div>
              </td>
              <td
                v-if="index===0"
                :rowspan="storePO.storePurchaseOrderDetails.length"
              >
                <b-form-textarea
                  size="sm"
                  v-model="storePO.description"
                  :placeholder="'Ghi chú'"
                  :rows="3"
                  :max-rows="3"
                  class="full-width-textarea"
                ></b-form-textarea>
              </td>
            </tr>
          </template>
          <tr v-show="!isBusy">
            <td
              :colspan="5"
              class="align-middle text-right icon-color font-weight-bolder"
            >
              Tổng: {{ sumQuantity }}
            </td>

            <td
              v-if="checkShowApproveQuantityColumn()"
              class="align-middle text-right icon-color font-weight-bolder"
            >
              Tổng: {{ sumQuantityApproved }}
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="8">
              <div class="text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Đang tải dữ liệu...</strong>
              </div>
            </td>
          </tr>
        </tbody>

      </table>
      <template #modal-footer>
        <b-button
          variant="primary"
          size="l"
          class="float-right"
          @click="onSubmit"
          v-show="!isBusy"
        >
          {{ buttonName }}
        </b-button>
        <b-button
          variant="secondary"
          size="l"
          class="float-right"
          @click="hideModal"
        >
          Huỷ bỏ
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
  
  <script>
import ApiService from '@/core/services/api.service';
import {
  makeToastFaile,
  currencyMask,
  makeToastSuccess,
  promiseQueue,
} from '@/utils/common';
import { formatDate, formatDBDate } from '@/utils/date';
import { STORE_PO_STATUS } from '@/utils/enum';
import sumBy from 'lodash/sumBy';
import timeUtils from '@/utils/date';
import { showConfirmNotify } from '@/utils/sweet-alert2';

export default {
  props: {
    selectedStorePurchaseOrder: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      require: true,
    },
  },
  watch: {
    action(newVal) {
      this.action = newVal;
      this.handleActionChange(this.action);
    },
    selectedStorePurchaseOrder: {
      handler(newVal) {
        this.getPurchaseOrderDetails(newVal);
      },
      immediate: true,
    },
  },
  data() {
    return {
      isBusy: false,
      mask: currencyMask,
      STORE_PO_STATUS: STORE_PO_STATUS,
      modifyStorePOSeleteds: [],
      title: 'Danh sách PO',
      dpConfigs: timeUtils.DP_CONFIG,
      buttonName: 'Lưu',
      confirmNotify: {
        title: '',
        text: 'Lưu ý! Thao tác này không thể hoàn tác!',
        actionName: 'Xác nhận',
        confirmButtonColor: '#3699FF',
      },
      batchSize: 10,
    };
  },
  computed: {
    sumQuantity() {
      let totalQty = 0;
      for (const details of this.modifyStorePOSeleteds) {
        totalQty += sumBy(details.storePurchaseOrderDetails, (storePO) =>
          Number(storePO.quantity),
        );
      }
      return totalQty;
    },
    sumQuantityApproved() {
      let totalQtyApproved = 0;
      for (const details of this.modifyStorePOSeleteds) {
        totalQtyApproved += sumBy(
          details.storePurchaseOrderDetails,
          (storePO) => Number(storePO.quantityApproved),
        );
      }
      return totalQtyApproved;
    },
    storePOComputed() {
      return this.modifyStorePOSeleteds;
    },
  },
  created() {},
  methods: {
    formatDate,
    handleActionChange(action) {
      switch (action) {
        case 'approve':
          this.title = 'Danh sách PO đang chờ duyệt';
          this.buttonName = 'Duyệt';
          this.confirmNotify.title = 'Bạn có chắc muốn duyệt tất cả phiếu!';
          break;
        case 'request-approval':
          this.title = 'Danh sách PO';
          this.buttonName = 'Yêu cầu duyệt';
          this.confirmNotify.title =
            'Bạn có chắc muốn yêu cầu duyệt tất cả phiếu!';
          break;
        case 'complete':
          this.title = 'Danh sách PO đang chờ hoàn tất';
          this.buttonName = 'Hoàn thành';
          this.confirmNotify.title =
            'Bạn có chắc muốn hoàn thành tất cả phiếu!';
          break;
      }
    },
    applyAllDeliveryDate(checkedId, deliveryDate) {
      this.modifyStorePOSeleteds.forEach((storePO) => {
        storePO.deliveryDate = deliveryDate;
        if (storePO.id !== checkedId) {
          storePO.isAutoFillDeliveryDate = true;
        }
      });
    },
    checkShowApproveQuantityColumn() {
      return this.action === 'approve' || this.action === 'complete';
    },
    checkShowConfirmQuantityColumn() {
      return this.action === 'complete';
    },
    checkShowDeliveryDateColumn() {
      return this.action === 'approve';
    },
    getBorderInput(row) {
      if (!row.isEdited) {
        return '';
      }
      if (row.isEdited && Number(row.quantityApproved)) {
        return 'border-success';
      } else {
        return 'border-danger';
      }
    },
    startEditingRow(row) {
      row.isEdited = true;
    },
    removeHighlightRow(id) {
      const rows = document.querySelectorAll(`.tr-${id}`);
      if (rows.length) {
        rows.forEach((item) => {
          item.classList.remove('table-hover');
        });
      }
    },
    highlightRow(id) {
      const rows = document.querySelectorAll(`.tr-${id}`);
      if (rows.length) {
        rows.forEach((item) => {
          item.classList.add('table-hover');
        });
      }
    },
    requestApproveStorePO(storePO) {
      return ApiService.put(
        `store-purchase-order/${storePO.id}/request-approval`,
        storePO,
      );
    },
    async multipleRequestApprovalStorePOs(storePOs) {
      return promiseQueue(storePOs, this.batchSize, this.requestApproveStorePO);
    },
    approveStorePO(storePO) {
      storePO.deliveryDate = formatDBDate(storePO.deliveryDate);
      return ApiService.put(
        `store-purchase-order/${storePO.id}/approve`,
        storePO,
      );
    },
    multipleApprovelStorePOs(storePOs) {
      return promiseQueue(storePOs, this.batchSize, this.approveStorePO);
    },
    completeStorePO(storePO) {
      return ApiService.put(
        `store-purchase-order/${storePO.id}/complete`,
        storePO,
      );
    },
    multipleCompleteStorePOs(storePOs) {
      return promiseQueue(storePOs, this.batchSize, this.completeStorePO);
    },
    dispatchStorePORequest(action, storePOs) {
      switch (action) {
        case 'request-approval':
          return this.multipleRequestApprovalStorePOs(storePOs);
        case 'approve':
          return this.multipleApprovelStorePOs(storePOs);
        case 'complete':
          return this.multipleCompleteStorePOs(storePOs);
        default:
          throw new Error(`Action: '${action}' không hợp lệ!`);
      }
    },
    async onSubmit() {
      try {
        const { isConfirmed } = await showConfirmNotify(this.confirmNotify);
        if (!isConfirmed) {
          return;
        }
        if (this.isBusy) return;
        this.isBusy = true;
        const responses = await this.dispatchStorePORequest(
          this.action,
          this.modifyStorePOSeleteds,
        );
        const successResponse = responses.find(
          ({ status }) => status === 'fulfilled',
        );
        if (successResponse) {
          const message = successResponse.value?.data?.message || 'Thành công';
          this.hideModal();
          this.$emit('refreshPage', true);
          makeToastSuccess(message);
        } else {
          const failedResponse = responses.find(
            ({ status }) => status === 'rejected',
          );
          const message =
            failedResponse.reason.response?.data?.message || 'Lỗi';
          makeToastFaile(message);
        }
      } catch (error) {
        const message = error.response.data.message ?? error.message;
        makeToastFaile(message);
      } finally {
        this.isBusy = false;
      }
    },
    async getPurchaseOrderDetails(selectedStorePurchaseOrder) {
      try {
        const id = selectedStorePurchaseOrder.map(({ id }) => id);
        if (!id || !id.length) return;
        this.isBusy = true;
        const params = {
          id,
        };
        const {
          data: { data },
        } = await ApiService.query('store-purchase-order/get-by-id', {
          params,
        });
        this.modifyStorePOSeleteds = data.map(
          ({ storePurchaseOrderDetails, ...storePO }) => {
            return {
              ...storePO,
              storePurchaseOrderDetails: this.mappingStorePurchaseOrderDetails(
                storePO,
                storePurchaseOrderDetails,
              ),
            };
          },
        );
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.isBusy = false;
      }
    },
    mappingStorePurchaseOrderDetails(storePO, storePurchaseOrderDetails = []) {
      const isApproved = storePO.status === STORE_PO_STATUS.APPROVED;
      const isRequestApproval =
        storePO.status === STORE_PO_STATUS.REQUEST_APPROVAL;
      return storePurchaseOrderDetails.map((detail, index) => {
        const { quantity, quantityApproved, quantityConfirmed } = detail;
        return {
          ...detail,
          count: ++index,
          quantityApproved: isRequestApproval ? quantity : quantityApproved,
          quantityConfirmed: isApproved ? quantityApproved : quantityConfirmed,
        };
      });
    },
    showModal() {
      this.$refs['multiple-approve-store-po-modal'].show();
    },
    hideModal() {
      this.$refs['multiple-approve-store-po-modal'].hide();
    },
  },
};
</script>
  
<style scoped>
.table-hover {
  background-color: #a8bbbf33;
}
.full-width-textarea {
  width: 100%;
  height: 100%;
}
</style>