<template>
  <div class="list-purchase-order-page">
    <KTCodePreview v-bind:title="'Danh sách phiếu yêu cầu nhập hàng'">
      <template v-slot:toolbar>
        <div class="row">
          <div v-if="false">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
            >
              <template slot="button-content">
                <i class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ml-3">
            <b-dropdown
              size="sm"
              right
              split
              variant="primary"
              @click="redirectToCreatePage()"
              class="ml-4"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span> <i class="flaticon2-add-1"></i>Tạo mới</span>
                </div>
              </template>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <!-- tag-->
        <b-row class="mb-5">
          <b-col md="3">
            <div class="search-col">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.date"
                v-model="params.fromDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.date"
                v-model="params.toDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col md="3">
            <b-form-input
              v-model.trim="params.code"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col
            v-if="checkPermission('STORE_PO_VIEW_ALL')"
            md="3"
          >
            <treeselect
              v-model="params.storeId"
              :options="listStoreGroup"
              :multiple="true"
              :flatten-search-results="true"
              :match-keys="['name', 'nameNoneSign']"
              placeholder="Hiển thị theo cửa hàng"
              noResultsText="Không tìm thấy cửa hàng"
            />
          </b-col>
          <b-col md="3">
            <Autosuggest
              class="border-radius-none"
              :model="searchEmployee"
              :suggestions="filteredEmployeeOptions"
              placeholder="Tìm kiếm theo người tạo"
              :limit="200"
              @select="onSelectedEmployee"
              @change="onSearchEmployeeChange"
              suggestionName="suggestionName"
            />
          </b-col>
        </b-row>
        <!--end tag-->
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              variant="primary"
              size="sm"
              class="btn font-weight-bold btn-size"
              @click="onFilter"
              :disabled="onLoading"
            >Lọc</b-button>
          </b-col>
          <b-col>
            <b-dropdown
              size="sm"
              class="mr-4"
              variant="warning"
              right
              v-if="countSelectedStorePO"
            >
              <template slot="button-content">
                <span class="font-weight-bolder">
                  <span class="count-status badge badge-circle badge-white mr-1">{{ countSelectedStorePO }}</span>
                  Đã chọn
                </span>
              </template>
              <b-dropdown-item
                v-if="checkPermission('STORE_PO_CREATE')"
                @click="openModalMultipleStorePO('request-approval')"
                :disabled="!storePOCanRequestApprovalMultiple.length"
              >
                <span class="mr-2">Yêu cầu duyệt</span>
                <b-badge variant="success">{{storePOCanRequestApprovalMultiple.length}}</b-badge>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission('STORE_PO_APPROVE')"
                @click="openModalMultipleStorePO('approve')"
                :disabled="!storePOCanApproveMultiple.length"
              >
                <span class="mr-2">Duyệt phiếu</span>
                <b-badge variant="success">{{storePOCanApproveMultiple.length}}</b-badge>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission('STORE_PO_APPROVE')"
                @click="openModalMultipleStorePO('complete')"
                :disabled="!storePOCanCompleteMultiple.length"
              >
                <span class="mr-2">Hoàn tất phiếu</span>
                <b-badge variant="success">{{storePOCanCompleteMultiple.length}}</b-badge>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-alert
          v-if="countStorePOWaitingApproval && checkPermission('STORE_PO_APPROVE')"
          show
          dismissible
          fade
          variant="warning"
          class="alert-hover"
        ><span
            type="button"
            @click="handleClickAlert()"
          >Bạn còn {{ countStorePOWaitingApproval }} phiếu đang chờ duyệt</span></b-alert>
        <b-nav
          class="my-3"
          tabs
          pills
        >
          <b-nav-item
            v-for="item in listStatus"
            :key="item.id"
            @click="handleFilterByStatus(item)"
            :active="item.id === selectedStatus"
          >
            {{ item.name }}
          </b-nav-item>
        </b-nav>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listStorePurchaseOrder"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template slot="head(checkbox)">
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAllRows"
            ></b-form-checkbox>
          </template>
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>
          <template #cell(checkbox)="row">
            <b-form-checkbox v-model="row.item.selected"></b-form-checkbox>
          </template>
          <template #header(checkbox)>
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAll"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(code)="row">
            <div class="code-col">
              <a
                class="font-weight-bold"
                @click="redirectPODetail(row.item)"
              >
                {{ row.item.code }}
              </a>
              <br />
              <p>{{ formatDate(row.item.createdAt, CLIENT_DATE_TIME) }}</p>
              <p>{{ row.item.createdByName }}</p>

            </div>
          </template>
          <template v-slot:cell(providerName)="row">
            <span v-text="row.item.providerName"></span>
            <div>
              <p class="text-success">
                {{ row.item.typeName }}
              </p>
            </div>
          </template>
          <template v-slot:cell(statusName)="row">
            <div>
              <span
                v-text="row.item.statusName"
                class="label font-weight-bold label-lg label-inline mb-2"
                :class="statusNameClasses(row.item.status)"
                style="width: max-content"
              ></span>
              <div v-if="row.item.status === STORE_PO_STATUS.PURCHASE ||
      row.item.status === STORE_PO_STATUS.ACCOUNTANT
      ">
                <p class="text-bold">
                  Duyệt lúc:
                  <i
                    v-b-tooltip.hover
                    :title="row.item.purchaseApproveByName"
                    class="fas fa-user-edit"
                  ></i>
                </p>
                <p class="text-date-po">{{ row.item.purchaseApproveDate }}</p>
              </div>
              <div v-if="row.item.status === STORE_PO_STATUS.ACCOUNTANT">
                <p class="text-bold">
                  Xác nhận:<i
                    v-b-tooltip.hover
                    :title="row.item.accountantApproveByName"
                    class="fas fa-user-check"
                  ></i>
                </p>
                <p class="text-date-po">{{ row.item.accountantApproveDate }}</p>
              </div>
            </div>
          </template>
          <template v-slot:cell(sentStatusName)="row">
            <span
              v-text="row.item.sentStatusName"
              class="label font-weight-bold label-lg label-inline"
              :class="statusNameClasses(row.item.sentStatus)"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(requestDate)="row">
            {{ formatDate(row.item.requestDate, DATE_FORMAT) }}
          </template>
          <template v-slot:cell(storeName)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.storeName"></span>
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item
                  @click="approveItem(row.item)"
                  v-if="checkShowApproveButton(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="fa-solid fa-paper-plane icon-size"></i>
                    &nbsp; Duyệt phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="requestApproveItem(row.item)"
                  v-if="row.item.status === STORE_PO_STATUS.NEW"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="fa-solid fa-paper-plane icon-size"></i>
                    &nbsp; Yêu cầu duyệt
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="completeItem(row.item)"
                  v-if="checkShowCompleteButton(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="fa-solid fa-check-double icon-size"></i>
                    &nbsp; Hoàn tất phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="confirmItem(row.item)"
                  v-if="checkShowConfirmButton(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="fa-solid fa-check-double icon-size"></i>
                    &nbsp; Xác nhận phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkShowEditButton(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="flaticon2-pen icon-size"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showModalCancelStorePO(row.item)"
                  v-if="checkShowCancelButton(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="fa-solid fa-ban icon-size text-danger"></i>
                    &nbsp; Huỷ phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkShowDeleteButton(row.item)"
                  @click="showDeleteAlert(`${modalDeleteTitle} ${row.item.code}`, modalDeleteDesc, row.item, confirmDeleteStorePO)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="flaticon2-rubbish-bin-delete-button icon-size text-danger"></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <BPaginationNavCustom
              :number-of-pages="totalPages || 1"
              :current-page="page"
              :total-page="totalPages"
              :linkParams="{}"
              @page-change="fetchData()"
            >
            </BPaginationNavCustom>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <CancelStorePoModal
            :cancelAlert="cancelAlert"
            @refreshPage="refreshPage"
            :checkedItem="cancelItem"
            ref="cancel-store-po-modal"
          />
        </template>
        <template>
          <multiple-approve-store-po
            v-if="countSelectedStorePO"
            :selectedStorePurchaseOrder="storePOMultipleData"
            @refreshPage="refreshPage"
            :action="action"
            ref="multiple-approve-store-po-modal"
          />
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import { STORE_PO_STATUS } from '@/utils/enum';
import 'vue-cute-timeline/dist/index.css';
import { convertPrice, makeToastFaile, removeAccents } from '@/utils/common';
import { DP_CONFIG, getSubtractDate } from '@/utils/date';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import {
  formatDBDate,
  formatDate,
  DATE_FORMAT,
  CLIENT_DATE_TIME,
} from '@/utils/date';
import { showDeleteAlert, showConfirmNotify } from '@/utils/sweet-alert2';
import { makeToastSuccess } from '../../../utils/common';
import CancelStorePoModal from '@/view/components/store-purchase-order/cancel-store-po';
import MultipleApproveStorePo from '@/view/components/store-purchase-order/multiple-approve-store-po';
import { checkPermission } from '@/utils/saveDataToLocal';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  data() {
    return {
      countStorePOWaitingApproval: 0,
      listStoreGroup: [],
      linkParams: {},
      cancelItem: null,
      cancelAlert: {
        actionName: 'Xác nhận',
        title: 'BẠN CÓ CHẮC MUỐN HUỶ PHIẾU!',
      },
      isModalVisible: true,
      modalDeleteTitle: 'Xoá phiếu yêu cầu nhập hàng!',
      modalDeleteDesc: 'Bạn có chắc muốn xoá. Thao tác này không thể hoàn tác!',
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      DATE_FORMAT: DATE_FORMAT,
      searchEmployee: '',
      filteredEmployeeOptions: [],
      isDisableStore: false,
      filteredOptionStore: [],
      allSelected: false,
      onLoading: false,
      listStorePurchaseOrder: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          sortable: false,
          thStyle: { width: '4%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '16%' },
        },
        {
          key: 'storeName',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'requestDate',
          label: 'Ngày yêu cầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'productCount',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'totalQty',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'totalQtyConfirmed',
          label: 'SL đã nhập',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? convertPrice(value) : 0;
          },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '10%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      listStatus: [
        {
          id: null,
          name: 'Tất cả trạng thái',
        },
        {
          id: STORE_PO_STATUS.NEW,
          name: 'Mới',
        },
        {
          id: STORE_PO_STATUS.REQUEST_APPROVAL,
          name: 'Yêu cầu duyệt',
        },
        {
          id: STORE_PO_STATUS.APPROVED,
          name: 'Đã duyệt',
        },
        {
          id: STORE_PO_STATUS.CONFIRMED,
          name: 'Đã xác nhận',
        },
        {
          id: STORE_PO_STATUS.CANCELD,
          name: 'Đã huỷ',
        },
        {
          id: STORE_PO_STATUS.COMPLETED,
          name: 'Đã hoàn thành',
        },
      ],
      selectedStatus: null,
      totalPages: 1,
      totalItems: 0,
      STORE_PO_STATUS: STORE_PO_STATUS,
      page: 1,
      limit: 10,
      dpConfigs: DP_CONFIG,
      params: {
        fromDate: getSubtractDate(15),
        toDate: null,
        storeId: null,
        createdBy: null,
        code: null,
        status: null,
        description: null,
      },
      action: null,
      storePOMultipleData: [],
    };
  },
  watch: {
    'params.fromDate': function () {
      if (this.validViewAllPermission()) {
        this.getListStoreGroup();
        this.getCountStorePOWaitingApproval();
      }
    },
    'params.toDate': function () {
      if (this.validViewAllPermission()) {
        this.getListStoreGroup();
        this.getCountStorePOWaitingApproval();
      }
    },
  },
  methods: {
    showDeleteAlert,
    formatDate,
    checkPermission,
    handleClickAlert() {
      this.handleFilterByStatus({
        id: STORE_PO_STATUS.REQUEST_APPROVAL,
      });
    },
    validViewAllPermission() {
      return checkPermission('STORE_PO_VIEW_ALL');
    },
    async getCountStorePOWaitingApproval() {
      const params = this.setParamFilter();
      const {
        data: { data },
      } = await ApiService.query(
        'store-purchase-order/count-waiting-approval',
        { params },
      );
      this.countStorePOWaitingApproval = data;
    },
    async getListStoreGroup() {
      if (!this.validViewAllPermission()) {
        return;
      }
      const params = this.setParamFilter();
      const {
        data: { data },
      } = await ApiService.query('store-purchase-order/group-by-store', {
        params,
      });
      this.listStoreGroup = data.map((item) => {
        return {
          ...item,
          label: item.name,
          nameNoneSign: removeAccents(item.name),
        };
      });
    },
    openModalMultipleStorePO(action) {
      this.action = action;
      this.storePOMultipleData = this.dispatchStorePOMultipleData(action);
      this.$refs['multiple-approve-store-po-modal'].showModal();
    },
    dispatchStorePOMultipleData(action) {
      switch (action) {
        case 'request-approval':
          return this.storePOCanRequestApprovalMultiple;
        case 'approve':
          return this.storePOCanApproveMultiple;
        case 'complete':
          return this.storePOCanCompleteMultiple;
      }
    },
    redirectPODetail(item) {
      this.$router.push({
        name: 'info-store-purchase-order',
        query: {
          id: item.id,
        },
      });
    },
    refreshPage() {
      this.fetchData();
    },
    refreshStorePOPage(billNumber) {
      this.$router.push({
        name: 'add-return-bill',
        query: { id: billNumber },
      });
    },
    showModalCancelStorePO(item) {
      this.cancelItem = item;
      this.$refs['cancel-store-po-modal'].showModal();
    },
    confirmDeleteStorePO(item) {
      ApiService.delete(`store-purchase-order/${item.id}`).then(({ data }) => {
        makeToastSuccess(data?.message);
        this.fetchData();
      });
    },
    checkShowDeleteButton(item) {
      return (
        item.status === STORE_PO_STATUS.NEW &&
        checkPermission('STORE_PO_DELETE')
      );
    },
    checkShowCancelButton(item) {
      const hideStatus = [STORE_PO_STATUS.CONFIRMED, STORE_PO_STATUS.CANCELD];
      return (
        !hideStatus.includes(item.status) && checkPermission('STORE_PO_CANCEL')
      );
    },
    checkShowCompleteButton(item) {
      return (
        item.status === STORE_PO_STATUS.CONFIRMED &&
        checkPermission('STORE_PO_APPROVE')
      );
    },
    checkShowConfirmButton(item) {
      return (
        item.status === STORE_PO_STATUS.APPROVED &&
        checkPermission('STORE_PO_CONFIRM')
      );
    },
    checkShowApproveButton(item) {
      const canAprovePO = [
        STORE_PO_STATUS.REQUEST_APPROVAL,
        STORE_PO_STATUS.APPROVED,
      ];

      return (
        canAprovePO.includes(item.status) && checkPermission('STORE_PO_APPROVE')
      );
    },
    checkShowEditButton(item) {
      return item.status === STORE_PO_STATUS.NEW;
    },
    navigateToPurchaseOrder(query = {}) {
      this.$router.push({
        name: 'info-store-purchase-order',
        query,
      });
    },
    redirectToCreatePage() {
      this.navigateToPurchaseOrder({ action: 'create' });
    },
    confirmItem(item) {
      this.navigateToPurchaseOrder({ action: 'confirm', id: item.id });
    },
    completeItem(item) {
      this.navigateToPurchaseOrder({ action: 'complete', id: item.id });
    },
    approveItem(item) {
      this.navigateToPurchaseOrder({ action: 'approve', id: item.id });
    },
    async requestApproveItem(item) {
      try {
        const { isConfirmed } = await showConfirmNotify({
          title: 'Yêu cầu duyệt',
          text: 'Phiếu yêu cầu duyệt sẽ không thể chỉnh sửa!',
          actionName: 'Xác nhận',
        });
        if (isConfirmed) {
          const response = await ApiService.put(
            `store-purchase-order/${item.id}/request-approval`,
          );
          makeToastSuccess(response.data.message);
          this.fetchData();
        }
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    editItem(item) {
      this.navigateToPurchaseOrder({ action: 'update', id: item.id });
    },
    onSelectedEmployee({ item }) {
      this.params.createdBy = item.id;
      this.searchEmployee = item.name;
    },
    onSearchEmployeeChange(text) {
      if (!text) {
        text = '';
        this.params.createdBy = null;
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredEmployeeOptions = [...filteredData];
    },
    async handleFilterByStatus({ id }) {
      this.params.status = id;
      this.selectedStatus = id;
      this.page = 1;
      await this.fetchData(this.page);
    },
    selectAllRows() {
      this.listStorePurchaseOrder = this.listStorePurchaseOrder.map(
        (element) => {
          return {
            ...element,
            selected: this.allSelected,
          };
        },
      );
    },
    onFilter() {
      this.fetchData();
    },
    setParamFilter() {
      return {
        ...this.params,
        fromDate: formatDBDate(this.params.fromDate),
        toDate: formatDBDate(this.params.toDate),
        page: this.page,
        limit: this.limit,
      };
    },
    fetchData: async function (page) {
      try {
        if (this.onLoading) return;
        this.onLoading = true;
        this.page = page ?? this.$route.query.page ?? 1;
        const params = this.setParamFilter();
        const {
          data: { data },
        } = await ApiService.query('store-purchase-order', {
          params,
        });
        this.listStorePurchaseOrder = data.result;
        this.totalItems = data.totalItems;
        this.totalPages = data.totalPages;
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.onLoading = false;
      }
    },
    async fetchEmployee() {
      try {
        const response = await ApiService.get('/employees/get-list-filter');
        const employees = response.data.data;
        this.optionsEmployee = employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = removeAccents(element.name);
          return {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
        });
        this.filteredEmployeeOptions = [...this.optionsEmployee];
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    isCanRequestApprovePO() {},
    isCanApprovePO() {},
    statusNameClasses(statusId) {
      const statusClassMapping = {
        [STORE_PO_STATUS.NEW]: 'label-light-warning',
        [STORE_PO_STATUS.APPROVED]: 'label-light-info',
        [STORE_PO_STATUS.CONFIRMED]: 'label-light-success',
        [STORE_PO_STATUS.CANCELD]: 'label-light-danger',
        [STORE_PO_STATUS.COMPLETED]: 'label-light-success',
      };
      return statusClassMapping[statusId] || 'label-light-dark';
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu nhập hàng', route: 'store-purchase-orders' },
      { title: 'Danh sách phiếu yêu cầu nhập hàng' },
    ]);
  },
  created() {
    this.fetchEmployee();
    this.getListStoreGroup();
    this.getCountStorePOWaitingApproval();
    const { status } = this.$route.query;
    if (status) {
      this.handleFilterByStatus({ id: status });
    } else {
      this.fetchData();
    }
  },
  computed: {
    selectedStorePurchaseOrder() {
      return this.listStorePurchaseOrder.filter((item) => item.selected);
    },
    countSelectedStorePO() {
      return this.selectedStorePurchaseOrder.length;
    },
    storePOCanRequestApprovalMultiple() {
      return this.selectedStorePurchaseOrder.filter(
        ({ status }) => status === STORE_PO_STATUS.NEW,
      );
    },
    storePOCanApproveMultiple() {
      return this.selectedStorePurchaseOrder.filter(
        ({ status }) => status === STORE_PO_STATUS.REQUEST_APPROVAL,
      );
    },
    storePOCanCompleteMultiple() {
      return this.selectedStorePurchaseOrder.filter(
        ({ status }) => status === STORE_PO_STATUS.CONFIRMED,
      );
    },
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    CancelStorePoModal,
    BPaginationNavCustom,
    MultipleApproveStorePo,
    Treeselect,
  },
};
</script>

<style scoped>
.btn-size {
  width: 90px;
}
.code-col > a {
  cursor: pointer;
}
.icon-size {
  font-size: 1rem;
}

.p-0 {
  padding: 0 !important;
}

.nav .nav-link {
  color: #3f4254;
  font-weight: 800;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.search-col {
  display: flex;
  align-items: center;
}

.code-col {
  justify-content: center;
  align-items: center;
  display: grid;
}
.alert-hover:hover {
  opacity: 0.6;
}
</style>
<style lang="scss" scoped>
.list-purchase-order-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }

  .text-bold {
    font-weight: 600;
    margin-bottom: unset;
    font-size: 12px;
  }

  .text-date-po {
    font-size: 10px;
    line-height: 1.2rem;
    margin-bottom: 0px;
  }
}
</style>